<template>
  <div
    class="container mb-5 rounded border border-dark mt-5"
    style="max-width: 600px;"
  >
    <div class="row justify-content-md-center">
      <div class="col-md-auto">
        <img
          width="80"
          height="80"
          alt=""
          loading="lazy"
          src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Matchday_Logo_Final_Transparent_2x_lhthq6.png"
        />จองสำเร็จ!
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-auto">
        <ProviderProfile :src="provider.logo" />
      </div>
      <div class="col p-1">
        <div>Match ID {{ match_id }}</div>
        <div>{{ court_type_detail }}</div>
        <!-- <div class="text-secondary">สำหรับ {{ team_size }} คน</div> -->
      </div>
      <div class="col col-md-auto p-4">X 1</div>
    </div>

    <div class="accordion p-3" id="accordionExample" v-if="createResult">
      <div class="card rounded border border-white">
        <div class="contain" id="hzeadingOne">
          <a
            class="default text-info"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <i class="fas fa-chevron-circle-down"></i>
            รายละเอียดการจอง
          </a>
        </div>
        <div
          id="collapseOne"
          class="collapse show multi-collapse bg-light"
          aria-labelledby="hzeadingOne"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col ">
                สถานที่
              </div>
              <div class="col col-md-auto">
                {{ provider.fullname }}
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col ">
                วันที่
              </div>
              <div class="col col-md-auto">
                {{ query_date }}
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col ">
                เวลา
              </div>
              <div class="col col-md-auto">
                {{ query_start }} -
                {{ query_end }}
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col ">
                การชำระเงิน
              </div>
              <div class="col col-md-auto">
                <span class="text-success" v-if="payment_success"
                  >ชำระแล้ว</span
                >
                <span class="text-danger" v-else>ค้างชำระ</span>
              </div>
            </div>
            <div v-if="!payment_success" class="row justify-content-between">
              <div class="col ">
                จำนวนเงินที่ต้องชำระ
              </div>
              <div class="col col-md-auto">
                {{ match_price }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="accordion p-3"
      id="accordionExample2"
      v-if="createResult.matches"
    >
      <div
        class="card rounded border border-white"
        v-if="createResult.matches.length > 1"
      >
        <div class="contain" id="headingTwo">
          <a
            class="default text-info"
            type="button"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <i class="fas fa-chevron-circle-down"></i>
            ข้อมูลการจองต่อเนื่อง {{ createResult.matches.length }} สัปดาห์
          </a>
        </div>
        <div
          id="collapseTwo"
          class="collapse show multi-collapse bg-light"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample2"
        >
          <div class="card-body">
            <table class="w-100">
              <thead>
                <tr>
                  <th>Match ID</th>
                  <th>วันที่</th>
                  <th>เวลา</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="match in createResult.matches" :key="match.id">
                  <td scope="row">{{ match.id }}</td>
                  <td>{{ getFormattedDate(match.time_start) }}</td>
                  <td>
                    {{ getTimeRangeString(match.time_start, match.time_end) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion p-3" v-if="friend_create">
      <div class="card rounded border border-white">
        <div class="contain" id="hzeadingThree">
          <a
            class="default text-info"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <i class="fas fa-chevron-circle-down"></i>
            ตั้งค่าแมทช์หาเพื่อนเล่น
          </a>
        </div>
        <div
          id="collapseThree"
          class="collapse show multi-collapse bg-light"
          aria-labelledby="hzeadingThree"
        >
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col ">
                จำนวนผู้เล่นที่ต้องการ
              </div>
              <div class="col col-md-auto">{{ friend_team_size }} คน</div>
            </div>
            <div class="row justify-content-between">
              <div class="col ">
                เพศ
              </div>
              <div class="col col-md-auto">
                {{ friend_gender }}
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col ">
                ช่วงอายุ
              </div>
              <div class="col col-md-auto">
                {{ friend_age_min }} - {{ friend_age_max }} ปี
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col ">
                รายละเอียดเพิ่มเติม
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col">
                <textarea
                  v-model="friend_message"
                  style="width: 100%; min-height:100px;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-center">
    <router-link to="/" class="btn btn-primary m-1">กลับหน้าหลัก</router-link>
  </div>
</template>

<script>
import UserProvider from "../resources/user_provider";

import styled from "vue3-styled-components";
import moment from "moment";

const ProviderProfile = styled.img`
  width: 106px;
  height: 108px;
  object-fit: cover;
`;

export default {
  components: {
    ProviderProfile
  },
  data() {
    let latest_created_match = JSON.parse(localStorage.getItem('latest_created_match'));
    const { match_id, email, match_price, provider, court_type_detail, match } = latest_created_match;
    const booking_detail = {}

    return {
      payment_success: this.payment_success,
      match_id: match_id,
      email: email,
      match_price: match_price,
      provider: provider,
      court_type_detail: court_type_detail,
      /* un-used */
      team_size: booking_detail.team_size,
      createResult: [match],
      query_type: booking_detail.query_type,
      query_court: booking_detail.query_court,

      query_date: moment(match.time_start,'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'),
      query_start: moment(match.time_start,'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
      query_end: moment(match.time_end,'YYYY-MM-DD HH:mm:ss').format('HH:mm'),

      friend_create: booking_detail.friend_create,
      friend_team_size: booking_detail.friend_team_size,
      friend_gender: booking_detail.friend_gender,
      friend_age_min: booking_detail.friend_age_min,
      friend_age_max: booking_detail.friend_age_max,
      friend_message: booking_detail.friend_message
    };
  },
  methods: {
    async checkPaymentStatus() {
      const userProvider = new UserProvider();
      const paymentStatus = await userProvider.checkPayment(this.match_id);

      this.payment_success = paymentStatus.status === "success" ? true : false;
    },
    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getTimeRangeString(time_start, time_end) {
      let start = moment(time_start).format("HH:mm");
      let end = moment(time_end).format("HH:mm");
      return `${start} - ${end}`;
    }
  },
  beforeMount() {
    this.checkPaymentStatus();
  }
};
</script>
